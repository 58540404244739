/*-------------------------
# TopBar
-------------------------*/

.top-bar{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-top: 30px;
    
    .wrapper{
        display: flex;
        align-items: center;
        .logo{
            max-width: 350px;
            margin-right: 40px;
            transition: all .5s;
            &:hover{
                transform: scale(.85);
            }
            img{
                width: 100%;
                height: auto;
                display: none;
            }
        }
        .logo img:nth-child(1){display: block;}
        .logo img:nth-child(2){display: none;}
        .logo img:nth-child(3){display: none;}
        .nav{
            .bars{
                display: none;
            }
            nav{
                @media screen and(min-width:$tablet){
                    .close-icon{display: none;}
                    .active-zone{display: none;}
                    .lat-bar-logo{display: none;}
                    & > ul{
                        display: flex;
    
                        & > li:not(.lat-bar-logo){
                            position: relative;
                            display: flex;
                            justify-content: center;
                            & > a{
                                position: relative;
                                color: #fff;
                                display: block;
                                padding: 10px 15px;
                                font: 1.2em $base-font;
                                cursor: pointer;                            
    
                                &:after{
                                    content: '';
                                    height: 2px;
                                    background-color: $base-color;
                                    position: absolute;
                                    top: 100%;                          
                                    left: 0;
                                    width: 0;
                                    transition: all .3s;
                                }
    
                                &:hover{
                                    &:after{
                                        width: 100%;
                                    }                                
                                }                            
                            }
    
                            &:hover > ul{
                                margin-top: 0;
                                opacity: 1;
                                visibility: visible;
                                transition-delay: 0s;
                            }
    
                            & > ul{
                                position: absolute;
                                top: 100%;
                                padding-top: 2px;
                                min-width: 100%;
                                transition: .2s .3s;
    
                                /*OUT*/
                                margin-top: 20px;
                                opacity: 0;
                                visibility: hidden;
    
                                li{
                                    background: #fff;
                                    position: relative;
                                    border-bottom: 1px solid #ddd;
    
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                    a{
                                        display: block;
                                        padding: 10px 20px;
                                        font: 300 0.9em $base-font;
                                        color: #666;
                                        text-align: center;
                                        white-space: nowrap;
                                        transition: all .2s;
    
                                        &:hover{
                                            background: $base-color;
                                            color: #fff;
                                        }
                                    }
                                }
                            }                        
                        }
                    }
                }
            }
        }
        .social{
            position: absolute;
            right: 0;
            height: 40px;
            top: 0;
            bottom: 0;
            margin: auto;

            ul{
                display: flex;

                li{
                    margin-left: 10px;

                    a{
                        color: #fff;
                        display: flex;
                        width: 40px;
                        height: 40px;
                        align-items: center;
                        justify-content: center;
                        transition: all .2s;
                        
                        &:hover{
                            background-color: $base-color;

                            &:hover > i{
                                font-size: 25px;
                            }
                        }
                        
                        i{
                            font-size: 30px;
                            transition: all .2s;
                        }
                    }
                }
            }
        }
    }

    .breadcrums{
        position: absolute;
        top: 100%;
        padding-top: 10px;
        width: 100%;

        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding-bottom: 25px;
            background: linear-gradient(to bottom,rgba(255, 255, 255, 0.9) 0,rgba(255, 255, 255, 0.9) 50%,transparent 93%,transparent 100%);
        }

        ul{
            width: 100%;
            display: flex;
            align-items: center;
            li{
                padding: 3px 5px;
                font: 400 0.9em $base-font;
                margin-right: 15px;
                color: #999;

                a::after{
                    content: '\f105';
                    font: 17px FontAwesome;
                    color: #ccc;
                    position: absolute;
                    left: 100%;
                    height: 100%;
                    display: flex;
                    top: 0;
                    margin-left: 10px;                        
                    pointer-events: none;
                }

                a{
                    position: relative;
                    display: block;
                    color: orange;
                    font-weight: 600;
                    cursor: pointer;
                    i{
                        color: $base-color;
                        font-size: 19px;
                    }                        
                }
            }
        }
    }

    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 40px;
        background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 50%,rgba(0,0,0,0) 93%,rgba(0,0,0,0) 100%);
    }

    &.shrink{
        padding: 15px 0;
        box-shadow: 0px 2px 1px rgba(0,0,0,0.3);
        .wrapper{
            .logo{
                width: 150px;
            }
            .logo img:nth-child(2){display: block;}
            .logo img:nth-child(1){display: none;}
            .logo img:nth-child(3){display: none;}
            nav{
                & > ul{
                    & > li:not(.lat-bar-logo){
                        & > a{
                            color: #666;
                        }
    
                        & > ul{
                            li{
                                box-shadow: 1px 1px 1px rgba(0,0,0,0.3)
                            }
                        }
                    }
                }
            }
            .social{
                position: absolute;
                right: 0;
                height: 40px;
                top: 0;
                bottom: 0;
                margin: auto;
    
                ul{
                    li{
                        a{
                            color: $base-color;
                            &:hover > i{
                                color: #fff;
                            }              
                        }
                    }
                }
            }
        }
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            padding: 0;
        }    
    }
}

@media screen and(max-width:$laptop-s){
    .top-bar {
        .wrapper {
            .logo{
                max-width: 230px;
            }
            nav {
                &>ul {
                    &>li:not(.lat-bar-logo) {
                        &>a { 
                            padding: 6px 10px;
                        }
                    }
                }  
            }
        }
    }
}

@media screen and(max-width:$tablet){
    .top-bar{
        padding: 10px 0;
        background-color: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,0.2);
        height: 55px;

        &:before{
            display: none;
        }
        .wrapper{
            justify-content: center;
            .logo{
                margin-right: 0;
                max-width: 130px;
            }

            .logo img:nth-child(3){display: block;}
            .logo img:nth-child(2){display: none;}
            .logo img:nth-child(1){display: none;}

            .social{
                display: flex;
                align-items: center;
                ul{
                    li{
                        a{
                            width: 30px;
                            height: 30px;
                            color: $base-color;
                            transition: none;
                            &:hover{
                                background: #fff;
                            }
                            i{
                                font-size: 25px;
                            }
                        }                        
                    }
                }
            }
            .nav{
                .bars{
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 24px;
                    height: 100%;
                    padding: 10px;
                    margin-left: -10px;
                    color: $base-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                nav{
                    position: fixed;
                    left: 0;
                    top: 0;
                    background-color: rgba(0,0,0,0);
                    width: 100%;
                    height: 100%;
                    visibility: hidden;
                    z-index: 100;
                    transition: all .3s;

                    .close-icon{
                        position: absolute;
                        right: 15px;
                        top: 10px;
                        color: #fff;
                        font-size: 25px;
                        pointer-events: none;
                    }
                    .active-zone{
                        position: absolute;
                        left:0;
                        top:0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }

                    &>ul{
                        position: relative;
                        z-index: 2;
                        width: 80%;
                        max-width: 350px;
                        height: 100%;
                        background-color: #fff;
                        box-shadow: 1px 0px 1px rgba(0,0,0,0.2);
                        display: block;
                        transform: translateX(-100%);
                        transition: all .3s;

                        &>li:not(.lat-bar-logo){
                            display: block;
                            border-bottom: 1px solid rgba(0,0,0,0.05);

                            &>a{
                                color: #999;
                                padding: 10px 15px;
                                display: block;
                                font: 1.2em Roboto;
                            }

                            &>a[href]{
                                color: #333;
                            }

                            ul{
                                padding-left: 20px;
                                background: rgba(0,0,0,0.05);
                                li{
                                    border-bottom: 1px solid rgba(0,0,0,0.05);
                                    &:last-child{
                                        border-bottom:0;
                                    }
                                    a{
                                        color: #333;
                                        padding: 10px 15px;
                                        display: block;
                                        font: 1.2em Roboto;
                                    }
                                }
                            }
                        }

                        .lat-bar-logo{
                            display: block;
                            padding: 15px;
                            background-color: rgba($base-color, 0.10);
                            border-bottom: 2px solid $base-color;
                            img{
                                position: relative;
                                display: block;
                                width: 100%;
                                height: auto;
                                margin: auto;
                                max-width: 180px;
                            }
                        }
                    }

                    &.show{
                        background:rgba(0,0,0,0.8);
                        visibility: visible;

                        & > ul{
                            transform: translateX(0);
                            overflow: auto;
                        }
                    }
                }
            }
        }
    }
}


/*-------------------------
# Slider
-------------------------*/

.slider{
    position: relative;
    height: 900px;
    width: 100%;
    & > .left,
    & > .right{
        position: absolute;
        height: 25%;
        width: auto;
        z-index: 10;
        margin: auto;
        top: 0;
        bottom: 0;
        display: block;
        background-color: transparent;
        border: none;
        opacity: .5;
        cursor: pointer;
    }
    & > .left{
        left: 30px;
        transform: rotateY(180deg);
    }
    & > .right{
        right: 30px;
    }
    .slide{
        position: relative;
        height: 900px;
        .images{
            background-color: #000;
        }
        .images, figure{
            @include fullwidth-absolute;
            figure{
                overflow: hidden;
                background-color: #000;
                margin: 0;
                opacity: 0.7;
                @include fullBg-img(center, center);
            }
        }
        .caption{
            position: absolute;
            margin: 0 auto;
            width: 90%;
            max-width: $max-width;
            bottom: 50px;
            left: 0;
            right: 0;
            ul{
                li{
                    h5{
                        font: bold 4em $base-font;
                        text-align: center;
                        color: #fff;
                        margin: 0;
                    }
                    h6{
                        font: 1.8em $base-font;
                        text-align: center;
                        color: #fff;
                        margin-bottom: 15px;
                    }
                    p{
                        font: 1em $base-font;
                        text-align: center;
                        color: #fff;
                        padding: 0 150px;
                        margin-bottom: 20px;
                    }
                    a{
                        position: relative;
                        display: block;
                        text-align: center;
                        width: 300px;
                        border-bottom: 3px solid $base-color;
                        background-color: rgba(224,145,46,.7);
                        padding: 15px 0;
                        margin: 0 auto;
                        color: #fff;
                        text-transform: uppercase;
                        margin-top: 30px;
                        font: 1.5em $base-font;
                        transition: all .5s;

                        &:after{
                            content: '';
                            height: 3px;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            background-color: #000;
                            width: 0;
                            transition: all .3s;
                        }

                        &:hover{
                            background-color: rgba(0,0,0,0.7);
                            color: $base-color;

                            &:hover:after{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and(max-width:$tablet){
    .slider{
        .right, .left{
            display: none !important;
        }

        .slide{
            .caption{
                ul{
                    li{
                        p{
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

/*-------------------------
# Carrousel 
-------------------------*/

.categories-block{
    position: relative;
    & > button{
        position: absolute;
        background: 0 0;
        border: none;
        font-size: 90px;
        height: 100%;
        z-index: 2;
        cursor: pointer;
        width: 90px;
        top: 0;
        color: $base-color;
    }
    .right{
        left: 100%;
    }
    .left{
        right: 100%;
    }
    .item{
        position: relative;
        height: 520px;
        width: (100% / 3);
        overflow: hidden;
        cursor: pointer;
        
        &:after{
            content: '';
            display: block;
            z-index: 2;
            background: linear-gradient(45deg, $base-color 25%,rgba(255,0,0,0) 80%);
            @include fullwidth-absolute;
            transition: all .3s;
        }

        &:hover:after{
            opacity: 0.5;            
        }
        &:hover > figure{
            transform: scale(1.1);
        }
        &:hover h2,&:hover small{
            text-shadow: 2px 2px 1px rgba(0,0,0,0.6)
        }

        a{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 100;
        }

        figure{
            z-index: 1;
            margin: 0;
            @include fullwidth-absolute;
            @include fullBg-img("top","center");
            transition: all .4s;
        }
        .caption{
            z-index: 3;
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            padding: 20px 35px;
            small{
                position: relative;
                color: #fff;
                text-transform: uppercase;
                font: 600 1.8em $base-font;
                margin-bottom: -5px;
                display: block;
                transition: all .5s;
            }
            h5{
                position: relative;
                color: #fff;
                font: 300 2.8em $base-font;
                text-transform: uppercase;
                transition: all .5s;
                margin-bottom: 0;
            }
        }
    }

    @media screen and(max-width:$laptop-m){    
        & > button{
            position: absolute;
            font-size: 30px;
            z-index: 10;
            cursor: pointer;
            width: 50px;
            height: 50px;
            top: 0;
            background-color: rgba(0,0,0,0.15);
            border: 1px solid rgba(0,0,0,0.25);
            color: #fff;
            bottom: 0;
            margin: auto;
        }
        .right{
            left: auto;
            right: 10px;
        }
        .left{
            right: auto;
            left: 10px;
        }        
    }

    @media screen and(max-width: $tablet){
        .item{
            height: 460px;
        }
    }

    @media screen and(max-width: $mobile-l){
        position: relative;
        font-size: 12px;

        .item{
            height: 350px;

            .caption{
                padding: 15px 20px;
            }
        }
    }
}


/*-------------------------
# Contents
-------------------------*/

// Left-img
.short-leftimg-content{
    position: relative;

    &:after{
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(225,225,225,1) 100%);
        z-index: -1;
    }
    .wrapper{
        display: flex;
        align-items: center;    
        figure{
            min-width: 40%;
            max-width: 40%;
            margin-right: 3%;
            margin-bottom: 0;
            margin-top: 0;
            img{
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .text{
            h5{
                text-align: center;
                font: 4em $base-font;
                margin-bottom: 5%px;
                color: $base-color;
            }
            p{
                text-align: center;
                font: 1em/150% $base-font;
                color: #333;
            }
            a{
                display: block;
                width: 180px;
                margin: 0 auto;
                background-color: rgba(255,255,255,0.3);
                color: $base-color;
                text-align: center;
                font: bold 1.2em sans-serif;
                border: 1px solid $base-color;
                border-radius: 4px;
                padding: 13px 0;
                text-transform: uppercase;
                margin-top: 30px;
                transition: all .3s;

                &:hover{
                    color: #fff;
                    background-color: $base-color;
                }
            }
        }
    }
    
    @media screen and(max-width:$laptop-s){
        .wrapper{
            figure{
                max-width: 50%;
                min-width: 50%;
                img{
                    left: -20%;
                    min-height: 150%;
                }
            }
            .text{
                h2{
                    font-size: 3em;
                }
            }
        }
    }

    @media screen and(max-width:$tablet){
        .wrapper{
            flex-direction: column;
            padding-bottom: 40px;

            figure{
                margin:0;
                width: 100%;
                max-width: 300px;
            }

            .text{
                padding-top: 30px;
                border-top: 1px solid #ccc;
            }
        }
    }

}

// Left Roundedimg
.left-roundimg-content{
    position: relative;
    display: flex;
    margin-bottom: 50px;
    margin-top: 50px;
    align-items: flex-start;
        
    figure{
        overflow: hidden;
        min-width: 25%;
        width: 25%;
        margin: 0 auto;
        margin-right: 50px;
        padding-top: 25%;        

        img{
            height: auto;
            width: auto;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            max-width: 100%;
            border-radius: 50%;
        }

       
    }
    .wrapper{
        position: relative;
        h3{}
        p{}
    }
}

@media screen and(max-width: $laptop-s){
    .left-roundimg-content{
        display: block;

        figure{
            width: 30%;
            margin: 0;
            margin-bottom: 5%;
            padding-top: 30%;
            float: left;
            border-radius: 50%;
            margin-right: 5%;           

            img{
                border-radius: 0;
            }
        }
    }
}

// Product block list (col 3, 4)
.products-block{
    position: relative;
    display: flex;   
    flex-wrap: wrap; 
    justify-content: space-between;
    margin-top: 4%;
    margin-bottom: -3%;

    &.nowrap{
        flex-wrap: nowrap;
        justify-content: center;
    }

    .block{
        position: relative;
        display: block;
        //margin-right: 20px;
        transition: all .2s;        

        &:last-child{
            margin-right: 0px;
        }

        a{
            @include fullwidth-absolute;
            cursor: pointer;
            z-index: 10;
        }
        figure{
            position: relative;
            display: flex;
            height: 400px;
            margin: 0;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
            overflow: hidden;
            transition: all .2s;

            img{
                min-height: 100%;
                max-height: 100%;
                width: auto;
                transition: all .2s;
            }
        }
        .ref{
            position: absolute;
            display: block;
            background: $base-color;
            top: 320px;
            right: 0;
            color: #fff;
            font: 600 16px $base-font;
            padding: 5px 6px;
            transition: all .2s;

            &:before{
                content: '';
                position: absolute;
                right: 100%;
                top: 0;
                border-right: 10px solid $base-color;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                transition: all .2s;
            }
        }
        p{
            text-align: center;
            font: 300 1em/130% $base-font;
            margin-top: 10px;
            margin-bottom: 0;
            padding: 0 10px;
            text-transform: LOWERCASE;

            &:first-letter{
                text-transform: uppercase;
            }
        }

        &:hover{
            border: 0px;

            figure{                
                box-shadow: 2px 2px 1px rgba(0,0,0,0.2);                
                transform: translate(-2px, -2px);

                img{
                    transform: scale(0.95);
                }
            }
            
            .ref{
                font-size: 13px;
                padding: 2px 3px;
                background-color: #333;

                &:before{
                    border-right: 10px solid #333;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }
            }
        }
    }

    &.col-4{
        .block{
            width: 23.5%;
            margin-bottom: 3%;

            &:empty{
                margin: 0;
            }
        }
    }
    &.col-3{
        .block{
            width: 32%;
            margin-bottom: 4%;
        }
    }    
    &.col-6{
        .block{
            width: 16.667%;
            margin-bottom: 3%;
        }
    }
    
    @media screen and(max-width: $laptop-s){
        &.col-4{
            .block{
                width: 30%;
            }
        }
        &.col-6{
            .block{
                width: 23%;
            }
        }
    }

    @media screen and(max-width: $tablet){
        &.col-6{
            .block{
                width: 30%;
            }
        }
    }

    @media screen and(max-width: $mobile-l){
        &.col-4{
            .block{
                width: 48%;
            }
        }
        &.col-6{
            .block{
                width: 48%;
            }
        }
    }

    @media screen and(max-width: $mobile-m){
        .block{
            figure{
                height: 320px;
            }
            .ref{
                top: 260px;
            }
        }
    }
}


// Product Details
.detailed-product{
    position: relative;
    display: flex;
    justify-content: space-between;
    .images{
        position: relative;
        width: 47%;
        display: flex;
        height: 600px;
        .thumbnails{
            position: relative;
            width: 20%;
            height: 100%;
            span{
                display: flex;
                height: 32%;
                justify-content: center;
                border: 1px solid #ddd;
                overflow: hidden;
                width: 100%;
                transition: all .1s;

                &:nth-child(2){
                    margin: 11% 0;
                }
                img{
                    display: block;
                    max-height: 100%;
                    width: auto;
                }

                &:hover{
                    border-width: 5px;
                }
            }
        }
        .zoom-img{
            width: 77%;
            margin-left: 3%;
            box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
            span{
                display: block;
                height: 100%;
                img:not([role]){
                    display: block;
                    width: auto;
                    height: auto;
                    max-height: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .caption{
        position: relative;
        width: 47%;
        h2{
            color: $base-color;
            text-transform: lowercase;

            &:first-letter{
                text-transform: uppercase;
            }
        }
        small{
            margin-bottom: 10px;
            line-height: 160%;
        }
        p{
            color: #666;
        }
        ul{
            margin-left: 50px;
            li{
                position: relative;
                margin-bottom: 15px;
                font: 400 1.2em Roboto;
                color: #666;

                &:before{
                    content: '\f05d';
                    font: 22px FontAwesome;
                    color: #f7941c;
                    position: absolute;
                    left: -30px;
                    height: 100%;
                    top: 2px;
                }
            }
        }
        .actions{
            display: flex;
            margin-top: 45px;
            button{
                width: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0;
                color: #fff;
                font: 400 1.2em $base-font;
                background-color: $base-color;
                transition: all .2s;
                cursor: pointer;

                i{
                    font-size: 25px;
                    margin-right: 10px;
                }
                &:nth-child(1){
                    border-radius: 4px 0 0 4px;
                    background-color: rgba( $base-color, .9 ) 
                }
                &:nth-child(2){
                    border-radius: 0 4px 4px 0;
                }

                &:hover{
                    background-color: #000;
                }
            }
        }
    }

    @media screen and(max-width: $laptop-s){
        .images{
            width: 50%;

            .thumbnails{
                width: 25%;
            }
            .zoom-img{
                width: 72%;
            }
        }
        .caption{
            width: 44%;

            .actions{
                button{
                    i{
                        font-size: 20px;
                    }
                }
            }
        }
    }
    
    @media screen and(max-width: $tablet){
        flex-direction: column;

        .images{
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 35px;

            .thumbnails{
                a{
                    &:nth-child(2){
                        margin: 8% 0;
                    }
                }
            }
        }
        .caption{
            width: 100%;
        }
    }

    @media screen and(max-width: $mobile-l){
        .images{
            height: 430px;
        }
    }

    @media screen and(max-width: $mobile-m){
        .caption{
            .actions{
                button{
                    i{
                        display: none;
                    }
                }
            }
        }

        .images{
            height: 365px;
        }
    }
}


//Fullwidth map and form
.fullwidth-map{
    position: relative;
    .map{
        position: relative;
        height: 800px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }

    .contact-form{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;
        z-index: 1;

        .wrapper{
            position: relative;
            background-color: rgba(255,255,255,0.8);
            display: block;
            top: 90px;
            width: 380px;
            border-radius: 7px;
            padding: 30px 40px;
            border: 1px solid orange;
            h2{
                font: 600 1.7em Roboto,sans-serif;
                text-align: center;
                i{
                    font-size: 40px;
                    color: orange;
                    margin-bottom: 13px;
                    margin-right: 15px;
                    top: 3px;
                    position: relative;
                }
            }
            p{
                font: 300 1em/130% Roboto,sans-serif;
                text-align: center;
                margin-top: -12px;
                margin-bottom: 20px;
            }
            form{
                position: relative;
                pointer-events: all;
                padding-bottom: 15px;
                .form-group{
                    display: block;
                    margin-bottom: 10px;
                    input,textarea{
                        background-color: #fff;
                        border: none;
                        width: 100%;
                        border-radius: 5px;
                        box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
                        padding: 13px 16px;
                        font: 300 1em Roboto;
                        color: #666;
                        resize: none;
                    }
                    input{}
                    textarea{
                        height: 120px;
                    }
                }
                button{
                    border-radius: 50%;
                    background-color: orange;
                    width: 70px;
                    height: 70px;
                    border: none;
                    font-size: 30px;
                    top: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    cursor: pointer;
                    transition: all .2s;

                    &:hover{
                        background-color: #000;
                    }

                    i{
                        color: #fff;
                    }

                    .btn-loader{
                        background-color: #000;
                    }
                }
                .msg{
                    display: block;
                    position: relative;
                    text-align: center;
                    font: 400 1em Roboto;
                    
                    &.danger{
                        color: red;
                    }
                    &.success{
                        color: green;
                    }
                }
            }
        }
    }

    @media screen and(max-width:$tablet){
        .contact-form{
            .wrapper{
                width: 330px;
            }
        }
        .map{
            height: 690px;
        }
    }

    @media screen and(max-width: $mobile-l){
        .contact-form{
            position: relative;
            height: auto;
            display: block;            

            .wrapper{                
                top: 0;
                margin: 0 auto;
                margin-top: 10%;
                margin-bottom: 60px;
                max-width: 330px;
                width: 90%;
            }
        }

        .map{
            height: 350px;
        }
    }

    @media screen and(max-width: $mobile-m){
        .contact-form .wrapper{
            padding: 10px 20px;

            form{
                .form-group{
                    textarea{
                        height: 80px;
                    }
                }
                button{
                    width: 50px;
                    height: 50px;
                    font-size: 25px;
                }
            }
        }
    }
}


/*-------------------------
# BlockListDown
-------------------------*/
.block-list{
    position: relative;
    overflow: hidden;
    transition: all .5s;
    padding: 10px;
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li{
            position: relative;
            width: 22%;
            margin-bottom: 4%;
            background-color: #fff;
            box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
            border-radius: 4px;
            padding-top: 12%;
            margin-right: 4%;
            transition: all .3s;

                @media screen and(min-width: $laptop-m){
                    &:nth-child(4n+4){
                        margin-right: 0%;
                    }
                }
                &:last-child{
                    margin-right: 0px;
                }
            a{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                figure{
                    @include fullwidth-absolute;
                    margin: 0;
                    img{
                        @include absolute-center;
                        max-width: 80%;
                        max-height: 80%;
                    }
                }
            }

            &:hover{
                box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
                transform: scale(1.05);
            }
        }
    }
    .view-more-action{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 12%;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%);
        transition: all .2s;
        cursor: pointer;
        span{
            position: absolute;
            display: block;
            bottom: 0px;
            text-align: center;
            width: 100%;
            font: 1.2em $base-font;
            text-transform: uppercase;
            color: #666;
        }
    }

    @media screen and(max-width:$tablet){
        ul{
            li{
                width: 31%;
                padding-top: 17%;
                margin-right: 3.5%;
                margin-bottom: 3.5%;

                &:nth-child(3n+3) {
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and(max-width: $mobile-l){
        ul{
            li{
                width: 48%;
                padding-top: 32%;
                margin-right: 4%;
                margin-bottom: 4vh;

                &:nth-child(3n+3) {
                    margin-right: auto;
                }

                &:nth-child(2n+2) {
                    margin-right: 0;
                }                
            }
        }

        .view-more-action{
            padding-top: 22%;
        }
    }
}


/*-------------------------
# CTA-leftGradientImg
-------------------------*/
.cta-leftGradientImg{
    display: flex;
    padding-left: 50%;
    position: relative;
    background-color: #f3f3f3;
    figure{
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        width: 50%;
        height: 100%;
        margin: 0;

        &:after{
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 15%;
            background: linear-gradient(to right, rgba(243,243,243,0) 0%,rgba(243,243,243,1) 100%);
        }
        img{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            min-height: 170%;
            width: auto;
        }
    }
    .caption{
        padding: 50px 50px 50px 40px;
        h5{
            font: 1.8em $base-font;
            text-align: center;
            margin-bottom: 30px;
            color: $base-color;
            strong{
                display: block;
                font-size: 2.2em;
            }
        }
        p{
            font: 300 1.25em/160% $base-font;
            text-align: center;
            margin-bottom: 50px;
            color: #444;
        }
        a{
            display: block;
            margin: 0 auto;
            border-radius: 4px;
            border: 1px solid $base-color;
            background: transparent;
            padding: 15px 25px 17px 25px;
            font: bold 1.8em $base-font;
            color: $base-color;
            cursor: pointer;
            max-width: 280px;
            text-align: center;
            transition: all .3s;
            i{
                position: relative;
                font-size: 1.2em;
                margin-right: 10px;
            }

            &:hover{
                background-color: $base-color;
                color: #fff;
            }
        }
    }

    @media screen and (max-width:$laptop-s){
        figure{
            img{
                left: -20%;
                min-height: 150%;
            }
        }
    }

    @media screen and(max-width: $tablet){
        font-size: 12px;

        figure{
            img{
                left: -40%;
            }
        }

        .caption{
            padding: 30px;

            h2{
                font-size: 1.6em;
                margin-bottom: 20px;
            }

            p{
                margin-bottom: 30px;
            }
        }
    }

    @media screen and(max-width:$mobile-l){
        flex-direction: column;
        padding: 0;

        figure{
            position: relative;
            height: 300px;
            width: 100%;

            &:after{
                right: auto;
                top: auto;
                bottom: 0;
                height: 20%;
                width: 100%;
                background: linear-gradient(to bottom,rgba(243,243,243,0) 0,#f3f3f3 100%);
            }
        }

        .caption{
            padding: 30px;
            margin-top: -55px;
            z-index: 1;
        }
    }
}

/*-------------------------
# FullwidthSubscribe
-------------------------*/
.subscriptionBar{
    background-color: #f7941c;
    padding: 30px 0;
    .wrapper{
        position: relative;
        display: flex;
        align-items: center;
        .caption{
            color: #fff;
            width: 25%;
            margin-right: 20px;
            p{
                margin-bottom: 0px;
                font: 400 1.6em $base-font;
                small{
                    font-weight: 300;
                    display: block;
                    font-size: 0.6em;
                }
            }
        }
        form{
            position: relative;
            width: 75%;
            .form-group{
                display: block;
                input{
                    width: 100%;
                    background: rgba(255,255,255,0.2);
                    border: 1px solid rgba(255,255,255,0.3);
                    padding: 15px 50px 15px 20px;
                    color: #fff;
                    font: 400 1.1em Roboto;
                    border-radius: 3px;
                    transition: all .2s;

                    &:focus{
                        background-color: transparent;
                    }
                }
            }
            button{
                display: block;
                cursor: pointer;
                color: #fff;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 60px;
                font-size: 25px;
                background: transparent;
                border: none;
                i{}

                .btn-loader{
                    background-color: $base-color;
                }
            }
            .msg{
                position: absolute;
                width: 100%;
                text-align: right;
                text-transform: uppercase;
                font: 300 0.8em Roboto;
                color: #fff;
                top: 100%;
                margin-top: 5px;
            }
        }
    }

    @media screen and(max-width:$tablet){
        .wrapper{
            flex-direction: column;

            .caption{
                width: 100%;
                margin: 0;
                margin-bottom: 15px;

                p{
                    text-align: center;
                }            
            }
            form{
                width: 100%;
            }
        }
    }
}


/*-------------------------
# Footer
-------------------------*/
.footer{
    background: $base-color;
    padding-bottom: 30px;
    .wrapper{
        position: relative;
        display: flex;
        .about-block{
            background-color: #f1f1f1;
            width: 20%;
            margin-right: 5%;
            margin-top: -30px;
            position: relative;
            box-shadow: 0 -1px 1px rgba(0,0,0,0.2);
            figure{
                padding: 20px 40px;
                background-color: #fff;
                margin: 0;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            h6{
                font: 600 1.35em Roboto;
                color: #333;
                text-align: center;
                margin: 20px 0 15px 0;
                padding: 0 20px;
            }
            p{
                color: #333;
                font: 300 0.85em/160% Roboto;
                text-align: center;
                padding: 0 20px;
            }
            a{
                text-transform: uppercase;
                font: 600 1em Roboto;
                color: orange;
                text-align: right;
                width: 100%;
                display: block;
                padding: 0 20px;
                margin-top: 15px;
                margin-bottom: 20px;
                border-right: 0px solid $base-color;
                transition: all .2s;

                &:hover{
                    border-right: 10px solid $base-color;
                    color: #333;
                }
            }
        }
        .actions-block{
            width: 75%;
            display: flex;
            flex-wrap: wrap;
            padding-top: 30px;
            h5{
                color: #fff;
                font: 600 1.6em $base-font;
                margin-bottom: 14px; 
                & + h6{
                    margin-top: -12px;
                }          
            }
            h6{
                font: 300 1.2em $base-font;
                color: #fff;
                margin-bottom: 14px; 
            }
            p{
                color: #fff;
                font: 300 1em Roboto;
                margin-bottom: 12px;
            }
            small{
                color: #fff;
                font: 300 0.85em Roboto;
                margin-bottom: 20px;
                display: block;
            }
            .nav{
                width: 25%;
                ul{
                    background: transparent;
                    li{
                        background: transparent;
                        position: relative;
                        left: 0;

                        transition: all .2s;
                        a{
                            position: relative;
                            display: block;
                            color: #fff;
                            font: 400 1em Roboto;
                            padding: 5px 0;
                            padding-left: 32px;
                            transition: all .2s;

                            &:before{
                                content: '\f105';
                                font: 18px FontAwesome;
                                position: absolute;
                                left: 10px;
                                top: 5px;
                                color: rgba(255,255,255,0.4);

                                transition: all .2s;
                            }                            
                        }

                        &:hover{
                            left: 10px;                          
                        }

                        &:hover > a::before{
                            color: rgba(255,255,255,0.8);
                            left: 15px;
                        }
                    }
                }
            }
            .subscribe{
                width: 32%;
                margin: 0 4.5%;
                form{
                    position: relative;
                    .form-group{
                        input{
                            width: 100%;
                            padding: 13px 15px;
                            border: none;
                            background-color: #fff;
                            font: 300 16px $base-font;
                            border:1px solid #fff;
                            transition: all .2s;

                            &:focus{
                                background-color: rgba(255,255,255,0.6);
                                border:1px solid #fff;
                            }
                        }
                    }
                    button{
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        width: 50px;
                        color: $base-color;
                        background: transparent;
                        border: none;
                        font-size: 20px;
                        cursor: pointer;
                        i{}

                        &:hover{
                            background-color: #fff;
                        }

                        .btn-loader{
                            background-color: #fff;
                            color: $base-color;
                            font-size: 8px;
                        }
                    }

                    .msg{
                        color: rgba(255, 255, 255, 0.8);
                        font: 13px/15px Roboto;
                        position: absolute;
                        top: 100%;
                        margin-top: 3px;
                    }
                }
                .social{
                    display: block;
                    position: relative;
                    margin-top: 25px;
                    h6{
                        font-weight: 600;
                    }
                    ul{
                        display: flex;
                        li{
                            margin-right: 10px;
                            a{
                                width: 35px;
                                height: 35px;
                                background-color: #fff;
                                border-radius: 2px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $base-color;
                                font-size: 19px;
                                border:1px solid #fff;
                                transition: all .2s;
                                i{
                                    transition: all .2s;
                                }                                

                                &:hover{
                                    background-color: transparent;
                                    & > i{
                                        color: #fff;
                                    }
                                }                               

                            }
                        }
                    }
                }
            }
            .contact{
                width: 34%;
                a{
                    display: block;
                    border: 1px solid #fff;
                    background: transparent;
                    border-radius: 3px;
                    padding: 10px 25px;
                    font: 600 1.3em Roboto;
                    color: #fff;
                    cursor: pointer;
                    margin-top: 20px;
                    text-align: center;
                    max-width: 200px;
                    transition: all .2s;
                    i{
                        margin-right: 14px;
                        font-size: 1.2em;
                        line-height: 26px;
                    }

                    &:hover{
                        background: #fff;
                        color: $base-color;
                    }
                }
            }
            .copy{
                width: 100%;
                text-align: center;
                margin-top: 35px;
                padding-top: 20px;
                border-top: 1px solid rgba(255,255,255,0.2);
                small{
                    margin-bottom: 8px;
                }
                ul{
                    display: flex;
                    justify-content: center;
                    li{
                        a{
                            color: rgba(255,255,255,0.6);
                            font: 300 0.85em Roboto;
                            padding: 5px 16px;

                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and(max-width: $tablet){
        .wrapper{
            .about-block{
                display: none;
            }
            .actions-block{
                width: 100%;
            }
        }
    }

    @media screen and(max-width:$mobile-l){
        .wrapper{
            .actions-block{
                flex-direction: column;
                .nav{
                    display: none;
                }
                &.actions-block > div{
                    width: 100%;
                    margin: 0;
                    margin-bottom: 10%;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                .copy{
                    ul{
                        li{
                            a{
                                padding: 5px 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and(max-width: $mobile-m){
        .wrapper{
            .actions-block{
                .copy{
                    ul{
                        li{
                            a{
                                padding: 5px 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}
