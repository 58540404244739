// Laptop Small
@media screen and(max-width: $laptop-s){
    html{font-size: 14px;}
}

@media screen and(max-width: $tablet){
    body{
        padding-top: 55px;
    }

    .slider{
        margin-top: -55px;
    }

    .mb-120{margin-bottom: 70px;}
    .mb-80{margin-bottom: 50px;}
    
    .content{
        margin-bottom: 50px;
    }

    aside{
        display: none;
    }
}

@media screen and(max-width: $mobile-l){
    html{font-size: 13px;}
    .content{
        margin-top: 20px;
    }
}