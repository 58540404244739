// SPACING

.mb-80{margin-bottom: 80px}
.mb-120{margin-bottom: 120px}

/////////////////////////////

@include keyframes(loadingDots){
    0%{content: '';}
    25%{content: '.';}
    50%{content: '..';}
    100%{content: '...';}
};

.no-scroll{
    overflow: hidden;
}

.btn-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    top: 0;
    left: 0;
}

.preloader{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 1000;
    flex-direction: column;

    img{
        width: 200px;
    }
    span{
        display: block;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid orange;
        width: 270px;
        text-align: center;
        font: 300 1.2em Roboto;

        &:after{
            content: '';
            @include loadingDots;
        }
    }
}

.container{
    width: 90%;
    max-width: $max-width;
    @include horizontal-center;
}

.content{
    position: relative;
    display: flex;
    margin-top: 40px;
    margin-bottom: 80px;

    aside{
        min-width: 25%;
        margin-right: 6%;

        .categories{
            h2{}
            ul{
                margin-left: 20px;
                margin-top: -10px;
                li{
                    position: relative;
                    padding-left: 20px;
                    a{
                        padding: 7px 5px;
                        display: block;
                        font: 400 1.2em Roboto;
                        color: #999;
                        transition: all .2s;

                        &:hover{
                            color: #333;
                            padding-left: 15px;
                        }
                    }

                    &:before{
                        content: "\f105";
                        font: 20px FontAwesome;
                        position: absolute;
                        left: 0px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        height: 17px;
                        color: #999;
                    }
                }
                li.active{
                    a{
                        padding-left: 15px;
                        color: #d1d1d1;
                    }
                    &:before{
                        left: 15px;
                    }
                }
            }
        }

        .lateral-form{
            background-color: #f7941c;
            border-radius: 4px;
            margin-top: 25px;
            h3{
                text-align: center;
                color: #fff;
                font: 600 1.9em $base-font;
                padding-top: 15px;
                margin-bottom: 0px;
            }
            p{
                text-align: center;
                padding: 0 20px;
                color: #fff;
                margin-bottom: 13px;
                line-height: 18px;

            }
            hr{
                border: none;
                border-top: 1px solid rgba(255,255,255,.2);
                border-bottom: 1px solid rgba(0,0,0,.1);
                margin-bottom: 20px;
            }
            form{
                padding: 0 30px 25px;
                width: 100%;
                .form-group{
                    input{
                        width: 100%;
                        border: none;
                        border-radius: 3px;
                        margin-bottom: 15px;
                        padding: 10px 15px;
                        color: #666;
                        font: 300 1em $base-font;
                        transition: all .2s;

                        &:focus{
                            background-color: rgba(255,255,255,0.15);
                            border: 1px solid #fff;
                            color: #fff;
                        }
                    }
                }

                @include clearfix;

                button{
                    position: relative;
                    padding: 10px 25px;
                    border: 1px solid #fff;
                    border-radius: 3px;
                    background: transparent;
                    color: #fff;
                    font-size: 1.1em;
                    font-weight: 300;
                    float: right;
                    cursor: pointer;

                    &:hover{
                        background-color: #fff;
                        color: $base-color;
                    }

                    .btn-loader{
                        background-color: #fff;
                        color: $base-color;
                    }
                }
            }
        }

        .contact-block{
            margin-top: 30px;
            position: relative;
            h2{
                font: 400 1.7em $base-font;
                color: orange;
                margin-bottom: 20px;
            }
            ul{
                margin-left: 20px;
                li{
                    color: #666;
                    font: 300 1em $base-font;
                    margin-bottom: 10px;
                    i{
                        color: $base-color;
                        margin-right: 10px;
                        font-size: 24px;
                    }
                }
            }
            hr{
                border: none;
                border-top: 1px solid $base-color;
                margin: 30px 0 25px;
                opacity: 0.4;
            }
            p{
                text-align: center;
                line-height: 22px;
                color: #666;
                margin-bottom: 15px;
            }
            a{
                display: block;
                background-color: $base-color;
                border-radius: 4px;
                text-align: center;
                color: #fff;
                font: 400 1.5em $base-font;
                padding: 12px 0;
                cursor: pointer;
                border: 1px solid transparent;

                transition: all .2s;
                
                &:hover{
                    color: $base-color;
                    background-color: transparent;
                    border: 1px solid $base-color;
                }
            }
        }
    }
    main{
        position: relative;
        width: 100%;
    }

    
}

.title-l1{
    color: $base-color;
    text-align: center;
    margin: 50px 0;
    font: 300 3em Roboto;
    strong{
        font-weight: 600;
    }
}
h1.underline,
h2.underline,
h3.underline{
    &:after{
        content: '';
        display: block;
        position: relative;
        width: 100%;
        background: url(../images/title-divider.png) center center no-repeat;
        background-size: auto 100%;
        height: 28px;
        margin-top: 20px;
    }
}