//Google Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);

//Fonts Variables
$base-font: 'Roboto', sans-serif;

//Colors
$base-color: #f7941c;

//Config
$max-width: 1300px;

//Break-points
$mobile-s: "321px";
$mobile-m: "400px";
$mobile-l: "550px";
$tablet: "769px";
$laptop-s: "1025px";
$laptop-m: "1441px";
$laptop-l: "1921px";
$_4k: "2561px";
