@mixin loadingDots {
    @include animate(loadingDots, 4s, infinite, linear);
}

//Font

//Positions

@mixin horizontal-center{
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
 @mixin fullwidth-absolute{
     position: absolute;
     left: 0;
     top: 0;
     height: 100%;
     width: 100%;     
 }
 @mixin absolute-center{
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     width: auto;
     height: auto;
     margin: auto;
     max-height: 100%;
 }

//Images
@mixin fullNoBg-img{
    position: absolute;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translateY(-25%);
}
@mixin fullBg-img($h-center, $v-center){
    background-position: $h-center $v-center;
    background-size: cover;
}

//Utilities

/* Clearfix */

@mixin clearfix{
    &:after{
        content:'';
        display: block;
        clear: both;
    }
}

@mixin animate($name, $duration, $iteration, $direction) {
            animation-duration: $duration;
            animation-iteration-count: $iteration;
            animation-name: $name;
            animation-direction: $direction;
}

@mixin keyframes($animationName) {
    @keyframes #{$animationName} {
        @content;
    }
}