h5{
    font: 400 1.7em $base-font;
    color: $base-color;
    margin-bottom: 4%;
}
h5+h1{
    font: 300 1.1em Roboto,sans-serif;
    color: #7d7d7d;
    margin-bottom: 4%;
    margin-top: -3.7%;
}
h6{
    font: 400 1.55em $base-font;
    color: $base-color;
    margin-bottom: 4%;
}

h5,h6{
    & ~ small{
        color: #666;
        font: 300 1.1em $base-font;
        margin-top: -2%;
        display: block;
        strong{
            font-weight: 600;
            color: $base-color;
        }
    }
}

p{
    font: 300 1em/180% Roboto,sans-serif;
    margin-bottom: 4%;
    text-align: justify;
}
figure{
    position: relative;
    width: 100%;
    margin: 5% 0;

    img{
        display: block;
        width: 100%;
    }
}